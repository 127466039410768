// extracted by mini-css-extract-plugin
export var Android = "DiscoverMobile-module--Android--77658";
export var arrowDiv = "DiscoverMobile-module--arrowDiv--288cd";
export var arrowImg = "DiscoverMobile-module--arrowImg--cafd5";
export var boxContent = "DiscoverMobile-module--boxContent--7b08c";
export var cards = "DiscoverMobile-module--cards--68d12";
export var cardsContent = "DiscoverMobile-module--cardsContent--aea59";
export var cardsZ = "DiscoverMobile-module--cardsZ--54977";
export var description = "DiscoverMobile-module--description--37ee2";
export var djangoArrowImg = "DiscoverMobile-module--djangoArrowImg--8f808";
export var estabBtn = "DiscoverMobile-module--estabBtn--c2ce0";
export var explorrBtn = "DiscoverMobile-module--explorrBtn--7958c";
export var finImg = "DiscoverMobile-module--finImg--eb685";
export var heading = "DiscoverMobile-module--heading--c2f55";
export var nav = "DiscoverMobile-module--nav--4ccb1";
export var navItem = "DiscoverMobile-module--nav-item--33a15";
export var navbarBlock = "DiscoverMobile-module--navbarBlock--a84e7";
export var processSteps = "DiscoverMobile-module--processSteps--94e68";
export var product1 = "DiscoverMobile-module--product1--0cf85";
export var soft = "DiscoverMobile-module--soft--1ea79";
export var softwareAccordianMobile = "DiscoverMobile-module--softwareAccordianMobile--71a0c";
export var tabData = "DiscoverMobile-module--tabData--3f669";
export var tabImg = "DiscoverMobile-module--tabImg--ab5fc";
export var tabbingCards = "DiscoverMobile-module--tabbingCards--eab28";
export var tech = "DiscoverMobile-module--tech--0e99b";