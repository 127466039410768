import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Proficiney.module.scss"
import proficency from "../../images/softwere-development/proficency.svg"

const Proficiney = ({ strapiData }) => {
  return (
    <div className={`${styles.Conversation}`}>
      <Container>
        <Row>
          <Col xl={5} lg={12}>
            <div className={styles.content}>
              <img
                src={proficency}
                decoding="async"
                loading="lazy"
                alt="proficency"
              />
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div className={styles.teamButton}>
                {strapiData?.buttons[0] && (
                  <Link
                    to={strapiData?.buttons[0]?.url}
                    className="btn_black_border"
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </div>
          </Col>
          <Col xl={7} lg={12}>
            <Row>
              {strapiData?.cards?.map((e, i) => (
                <Col lg={6} md={6} xs={12} className="gap-50" key={i}>
                  <div>
                    <div className={styles.cards}>
                      <img
                        src={e?.image1[0]?.localFile?.publicURL}
                        decoding="async"
                        loading="lazy"
                        alt={e?.title}
                      />
                      <h3>{e?.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <div className={styles.teamButton2}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_black_border"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Proficiney
