import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import carton from "../../images/softwere-development/carton.svg"
import * as styles from "./ChooseInvozone.module.scss"

const ChooseInvozone = ({ strapiData }) => {
  return (
    <div className={`${styles.Conversation} headingYellow`}>
      <Container>
        <div className={styles.cartonImg2}>
          <img decoding="async" loading="lazy" src={carton} alt="carton" />
        </div>
        <Row>
          <Col lg={4}>
            <div className={styles.content}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div className={styles.teamButton}>
                <Link
                  to={strapiData?.buttons[0]?.url}
                  variant="light "
                  className="btn_white_border"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <Row className="gap-30">
              {strapiData?.cards.map((e, i) => (
                <Col lg={6} md={6} xs={12} key={i}>
                  <div className={styles.cards}>
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
            </Row>
            <div className={styles.teamButton2}>
              <Link
                to={strapiData?.buttons[0]?.url}
                variant="light "
                className="btn_white_border_yellow"
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            </div>
          </Col>
        </Row>
        <div className={styles.cartonImg}>
          <img decoding="async" loading="lazy" src={carton} alt="carton" />
        </div>
      </Container>
    </div>
  )
}

export default ChooseInvozone
