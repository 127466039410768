// extracted by mini-css-extract-plugin
export var banerImg = "Banner-module--banerImg--2af35";
export var banner = "Banner-module--banner--dd623";
export var bannerBtn = "Banner-module--bannerBtn--e8a64";
export var bannerHeading = "Banner-module--bannerHeading--6c1c4";
export var btn_white2_border = "Banner-module--btn_white2_border--0f741";
export var cards = "Banner-module--cards--28a14";
export var fullCycleDesc = "Banner-module--fullCycleDesc--7af5d";
export var head = "Banner-module--head--887d8";
export var heading = "Banner-module--heading--b506b";
export var talent = "Banner-module--talent--112b0";
export var text = "Banner-module--text--2a9d6";
export var tick = "Banner-module--tick--8acad";