import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import arrow from "../../images/mobile-app-developmemt/arrow.svg"
import shape1 from "../../images/softwere-development/Ellipse395.svg"
import shape2 from "../../images/softwere-development/Ellipse396.svg"
import first from "../../images/softwere-development/product1.png"
import second from "../../images/softwere-development/product2.png"
import third from "../../images/softwere-development/product3.png"
import fourth from "../../images/softwere-development/product4.png"
import fifth from "../../images/softwere-development/product5.png"
import sixth from "../../images/softwere-development/product6.png"
import "./Discover.scss"
import * as styles from "./Discover.module.scss"
const Discover = ({ strapiData }) => {
  let defaultActiveTab = strapiData?.cards?.[0]?.title || "default"
  return (
    <div className={`${styles.softwareTabs} softwareDevelopment`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <div className={styles.Android}>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={defaultActiveTab}
          >
            <Row>
              <Col lg={5}>
                <div className={styles.soft}>
                  <Nav variant="pills" className={`software`}>
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Nav.Item key={i}>
                          <div className={styles.tabImg}>
                            <Nav.Link eventKey={`${e?.title}`}>
                              {e?.title}
                            </Nav.Link>
                          </div>
                        </Nav.Item>
                      ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={7}>
                <div className="tabs">
                  <Tab.Content className="reactTab">
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Tab.Pane eventKey={`${e?.title}`} key={i}>
                          <div className={styles.boxContent}>
                            <div>
                              <div className="d-flex justify-content-between">
                                <div className={`  ${styles.arrowDiv} `}>
                                  {e?.buttons[0]?.url && (
                                    <Link to={e?.buttons[0]?.url}>
                                      <img
                                        className={styles.arrowImg}
                                        src={arrow}
                                        decoding="async"
                                        loading="lazy"
                                        alt="arrow"
                                      />
                                    </Link>
                                  )}
                                </div>
                              </div>
                              <h3>{e?.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.description?.description,
                                }}
                              ></p>
                              <img
                                className={styles.processSteps}
                                src={
                                  i === 0
                                    ? first
                                    : i === 1
                                    ? second
                                    : i === 2
                                    ? third
                                    : i === 3
                                    ? fourth
                                    : i === 4
                                    ? fifth
                                    : i === 5
                                    ? sixth
                                    : ""
                                }
                                alt={e?.title}
                              />
                            </div>
                            <img
                              src={shape2}
                              className={styles.shape2}
                              decoding="async"
                              loading="lazy"
                              alt="arrow"
                            />
                            <img
                              src={shape1}
                              className={styles.shape1}
                              decoding="async"
                              loading="lazy"
                              alt="arrow"
                            />
                          </div>
                        </Tab.Pane>
                      ))}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}
export default Discover
