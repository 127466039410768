// extracted by mini-css-extract-plugin
export var FieldProvenWeb = "FieldProven-module--FieldProvenWeb--ef228";
export var arrowImg = "FieldProven-module--arrowImg--6e17f";
export var card = "FieldProven-module--card--d3767";
export var cardWrapper = "FieldProven-module--cardWrapper--d1b74";
export var description = "FieldProven-module--description--65c69";
export var heading = "FieldProven-module--heading--c5596";
export var loader = "FieldProven-module--loader--51b37";
export var lodaMoreBtn = "FieldProven-module--lodaMoreBtn--57e94";
export var lodalessBtn = "FieldProven-module--lodalessBtn--7a268";
export var showlessdiv = "FieldProven-module--showlessdiv--6a858";
export var spin = "FieldProven-module--spin--8cf14";
export var trailBg = "FieldProven-module--trailBg--47e89";