import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Testimonials2 from "../components/healthcare/Testimonials2"
import Banner from "../components/softwere-development/Banner"
import ChooseInvozone from "../components/softwere-development/ChooseInvozone"
import Conversation from "../components/softwere-development/Conversation"
import Establishing from "../components/softwere-development/Establishing"
import FieldProven from "../components/softwere-development/FieldProven"
import GotProject from "../components/softwere-development/GotProject"
import Discover from "../components/softwere-development/Discover"
import Outsource from "../components/softwere-development/Outsource"
import Proficiney from "../components/softwere-development/Proficiney"
import TechnologiesUse from "../components/softwere-development/TechnologiesUse"
import Testimonials from "../components/softwere-development/Testimonials"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Process from "../components/softwere-development/Process"
import MainLayout from "../layouts/MainLayout"
import DiscoverMobile from "../components/softwere-development/DiscoverMobile"

const SoftwareDevelopmentServices = ({ data }) => {
  //console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const fieldProven = data?.strapiPage?.sections[1]
  const conversation = data?.strapiPage?.sections[2]
  const makeCapital = data?.strapiPage?.sections[6]
  const engagment = data?.strapiPage?.sections[8]
  const faqs = data?.strapiPage?.sections[9]
  const testimonials = data?.strapiPage?.sections[12]
  const softwareProcess = data?.strapiPage?.sections[13]
  const techStacks = data?.strapiPage?.sections[14]
  const software = data?.strapiPage?.sections[15]
  const chooseWhy = data?.strapiPage?.sections[16]
  const hireSoftware = data?.strapiPage?.sections[17]
  const bestSoftware = data?.strapiPage?.sections[18]
  const establihed = data?.strapiPage?.sections[19]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={software} breadCrumb={data?.strapiPage?.buttons} />
      <FieldProven strapiData={fieldProven} />
      <Conversation strapiData={conversation} />
      <Outsource strapiData={bestSoftware} />
      <ChooseInvozone strapiData={chooseWhy} />
      <Establishing strapiData={establihed} />
      <GotProject pageName="Software Development Services" />
      <Process strapiData={softwareProcess} />
      {!isMobile && !isTablet ? (
        <Discover strapiData={makeCapital} />
      ) : (
        <DiscoverMobile strapiData={makeCapital} />
      )}
      <Proficiney strapiData={hireSoftware} />
      <EngagementModels strapiData={engagment} software={true} />
      <TechnologiesUse strapiData={techStacks} />
      <WorkPortfolio
        portfolioHead={"Our Work"}
        portfolioDesc={
          "Our exceptional Custom Software Development Services are top notch and they speak for themselves."
        }
      />
      {!isMobile && !isTablet ? (
        <Testimonials
          strapiData={testimonials}
          testiHead={"The Clients Have Spoken!"}
          testiSubtitle={
            "We are not just another company! We offer complete custom software development services to our partners."
          }
        />
      ) : (
        <Testimonials2
          strapiData={testimonials}
          testiHead={"The Clients Have Spoken!"}
          testiSubtitle={
            "We are not just another company! We offer complete custom software development services to our partners."
          }
        />
      )}
      <Faqs strapiData={faqs} pageName="Software Development Services" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "software-development" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            localFile {
              publicURL
            }
          }
          image2 {
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default SoftwareDevelopmentServices

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/software_development_services_a9dffe1260.svg"
    />
  )
}
