import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import arrow from "../../images/softwere-development/arrow.svg"

import * as styles from "./Outsource.module.scss"

const Outsource = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards.map((e, i) => (
            <Col lg={6} md={6} xs={12} key={i}>
              {e?.buttons[0]?.url && (
                <Link to={e?.buttons[0]?.url}>
                  <div className={styles.card}>
                    {e?.buttons[0]?.url && (
                      <Link to={e?.buttons[0]?.url}>
                        <img
                          className={styles.arrowImg}
                          src={arrow}
                          decoding="async"
                          loading="lazy"
                          alt="arrow"
                        />
                      </Link>
                    )}
                    <img
                      decoding="async"
                      loading="lazy"
                      src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title}
                    />
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Link>
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Outsource
