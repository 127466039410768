import { Link } from "gatsby"
import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import first from "../../images/softwere-development/product1.png"
import second from "../../images/softwere-development/product2.png"
import third from "../../images/softwere-development/product3.png"
import fourth from "../../images/softwere-development/product4.png"
import fifth from "../../images/softwere-development/product5.png"
import sixth from "../../images/softwere-development/product6.png"
import "./DiscoverMobile.scss"
import * as styles from "./DiscoverMobile.module.scss"

const DiscoverMobile = ({ strapiData }) => {
  const [activeAccordion, setActiveAccordion] = useState(0)

  return (
    <div className="softwareDevelopmentAccordian">
      <div className={`${styles.softwareAccordianMobile}`}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          />
          <div className={styles.Android}>
            <Accordion defaultActiveKey={`${activeAccordion}`}>
              {strapiData?.cards &&
                strapiData?.cards?.map((e, i) => (
                  <Card key={i}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={`${i}`}
                      onClick={() => setActiveAccordion(i)}
                      className={`SoftwareAccord ${
                        activeAccordion === i ? "active-accordion-software" : ""
                      } ${
                        (i === 1 || i === 3 || i === 5) &&
                        e?.title !== activeAccordion
                          ? "second-accordion-software"
                          : (i === 0 || i === 2 || i === 4) &&
                            e?.title !== activeAccordion
                          ? "first-accordion-software"
                          : ""
                      }`}
                    >
                      {e?.title}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${i}`}>
                      <Card.Body>
                        <div className={styles.boxContent}>
                          <div>
                            <div className="d-flex justify-content-between">
                              <div className={`  ${styles.arrowDiv} `}>
                                {e?.buttons[0]?.url && (
                                  <Link to={e?.buttons[0]?.url}>
                                    <img
                                      className={styles.arrowImg}
                                      src={arrow}
                                      decoding="async"
                                      loading="lazy"
                                      alt="arrow"
                                    />
                                  </Link>
                                )}
                              </div>
                            </div>
                            <h3>{e?.title}</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.description?.description,
                              }}
                            ></p>
                            <img
                              className={styles.processSteps}
                              src={
                                i === 0
                                  ? first
                                  : i === 1
                                  ? second
                                  : i === 2
                                  ? third
                                  : i === 3
                                  ? fourth
                                  : i === 4
                                  ? fifth
                                  : i === 5
                                  ? sixth
                                  : ""
                              }
                              alt={e?.title}
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
            </Accordion>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default DiscoverMobile
