// extracted by mini-css-extract-plugin
export var content = "Testimonials-module--content--02664";
export var iconContainer = "Testimonials-module--iconContainer--affab";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--26a5a";
export var imgBtn = "Testimonials-module--imgBtn--54a44";
export var person = "Testimonials-module--person--9acc5";
export var personBg = "Testimonials-module--personBg--91d64";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--532bb";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--0bef9";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--6495e";
export var row = "Testimonials-module--row--246f7";
export var section = "Testimonials-module--section--5c8c6";
export var testName = "Testimonials-module--testName--521eb";
export var testimonials = "Testimonials-module--testimonials--d427d";
export var testimonialsContent = "Testimonials-module--testimonialsContent--4fdd5";