// extracted by mini-css-extract-plugin
export var Android = "Discover-module--Android--89ac2";
export var arrowDiv = "Discover-module--arrowDiv--503c2";
export var arrowImg = "Discover-module--arrowImg--b830a";
export var boxContent = "Discover-module--boxContent--8f80f";
export var cards = "Discover-module--cards--684a7";
export var cardsContent = "Discover-module--cardsContent--96fed";
export var cardsZ = "Discover-module--cardsZ--14d7f";
export var description = "Discover-module--description--01da7";
export var djangoArrowImg = "Discover-module--djangoArrowImg--a611e";
export var estabBtn = "Discover-module--estabBtn--cc07d";
export var explorrBtn = "Discover-module--explorrBtn--916b5";
export var finImg = "Discover-module--finImg--9d8ac";
export var heading = "Discover-module--heading--96532";
export var processSteps = "Discover-module--processSteps--75a7a";
export var product1 = "Discover-module--product1--7d6bd";
export var shape1 = "Discover-module--shape1--b516e";
export var shape2 = "Discover-module--shape2--6eeda";
export var soft = "Discover-module--soft--9b3c9";
export var softwareTabs = "Discover-module--softwareTabs--e1185";
export var tabImg = "Discover-module--tabImg--e2790";
export var tabbingCards = "Discover-module--tabbingCards--c9ac0";