import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import { encodeToFormData, isEmail } from "../../utils"
import * as styles from "./GotProject.module.scss"
import { navigate } from "gatsby"

const GotProject = ({ pageName }) => {
  const [status, setStatus] = useState("init")
  const initValues = { fullName: "", email: "", message: "", page: pageName }
  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const handleChange = e => {
    const { name, value } = e.target
    values[name] = value.trimStart().replace(/\s+/g, " ")
    setValues({ ...values })

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "email":
          if (value.trim() === "") newErrors[key] = "This field is required!"
          else if (!isEmail(value)) newErrors[key] = "Email must be valid!"
          break
        case "message":
          if (value.trim() === "") newErrors[key] = "This field is required!"
          break
        case "fullName":
          if (value.trim() === "") newErrors[key] = "This field is required!"
          break
        default:
          break
      }
    }
    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validate(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }
    setStatus("submitting")
    const url = `${process.env.GATSBY_STRAPI_API_URL}/api/contactusforms/?id=9027`
    const formData = encodeToFormData(values)
    try {
      const result = await fetch(url, {
        method: "POST",
        body: formData,
      }).then(res => {
        if (res.status === 429) {
          setStatus("failed")
          return
        }
        return res.json()
      })
      // console.log(result.status)
      if (
        !result.hasOwnProperty("error") ||
        result.status === "mail_sent" ||
        result.status === "mail_failed"
      ) {
        // setValues(initValues)
        // setStatus("success")
        // setTimeout(() => {
        //   setStatus("init")
        // }, 2000)
        return navigate("/thank-you/", {
          state: { pathname: window.location.pathname },
        })
      } else {
        // console.log(result)
        setStatus("failed")
        throw new Error("Server error!")
      }
    } catch (err) {
      setStatus("failed")
    }
  }

  return (
    <div className={`${styles.gotProject}`}>
      <Container>
        <div className={styles.form}>
          <div className={styles.projectForm}>
            <div>
              <div className={styles.formData}>
                <h2>We’ll Handle Your Technical Needs Like A Breeze</h2>
                <div className="formContent1">
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="name" className="mb-1">
                        <Form.Label className="font-weight-normal px-1"></Form.Label>
                        <Form.Control
                          type="text"
                          name="fullName"
                          value={values.fullName}
                          onChange={handleChange}
                          isInvalid={errors.fullName}
                          placeholder="Full Name*"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-left position-absolute"
                        >
                          {errors.fullName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="name" className="mb-1">
                        <Form.Label className="font-weight-normal px-1"></Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={errors.email}
                          placeholder="Email Address*"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-left position-absolute"
                        >
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group controlId="message" className="pt-3 pt-md=0">
                        <Form.Label className="font-weight-normal px-1">
                          Message <span className="text-danger">*</span>
                        </Form.Label>

                        <Form.Control
                          as="textarea"
                          rows={7}
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          isInvalid={errors.message}
                          placeholder="Drop us a message*"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-left "
                        >
                          {errors.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} className="text-left">
                      <button
                        className={`btn_white_border_yellow mt-3`}
                        type="submit"
                        disabled={status === "submitting" ? true : false}
                        onClick={handleSubmit}
                      >
                        {status === "submitting" ? (
                          <React.Fragment>
                            <Spinner
                              variant="light"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Sending</span>
                          </React.Fragment>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </Col>
                    <Col>
                      {status === "success" ? (
                        <p
                          className=" m-0 mt-2 w-100 text-left  "
                          style={{ fontSize: "12px,", color: "#0FC30F" }}
                        >
                          Submit Successfully
                        </p>
                      ) : status === "failed" ? (
                        <p
                          className="text-danger  m-0 mt-2 w-100 text-left "
                          style={{ fontSize: "12px" }}
                        >
                          Somthing Went Wrong
                        </p>
                      ) : (
                        <p className="m-0 mt-2 w-100 text-left"></p>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div className={styles.briefSec}>
              <div className={styles.brief}>
                <h3>
                  A Sneak Peak Of Our Excellence In The Software Development
                  Industry
                </h3>
                <div className={styles.containerIntro}>
                  <div className={styles.briefDot}></div>
                  <h4>Mature Community </h4>
                  <div>
                    <p>
                      InvoZone is the beacon of 500+ experienced software
                      developers.
                    </p>
                  </div>
                </div>
                <div className={styles.containerIntro}>
                  <div className={styles.briefDot}></div>
                  <h4>Early Adopters </h4>
                  <div>
                    <p>
                      We have swiftly incorporated the latest languages, such as
                      Elixir, Python, Flutter, and RoR, to develop
                      well-fortified software.
                    </p>
                  </div>
                </div>
                <div className={styles.containerIntro}>
                  <div className={styles.briefDot}></div>
                  <h4>Timely Delivery </h4>
                  <div>
                    <p>
                      Employing agile software development practices enables us
                      to speed up the processes and increase efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default GotProject
